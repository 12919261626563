import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import PropTypes from 'prop-types';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from '../../config/prismic/preview';
import { STATUS } from '../constants/enum';

const ReduxWrapper = ({ element }) =>
  process.env.LOAD_PRISMIC_PREVIEWS === STATUS.TRUE ? (
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <Provider store={store}>{element}</Provider>
    </PrismicPreviewProvider>
  ) : (
    <Provider store={store}>{element}</Provider>
  );

export default ReduxWrapper;

ReduxWrapper.propTypes = {
  element: PropTypes.string
};
