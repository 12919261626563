import React, { useState, useEffect } from 'react';
import Header from '../components/LongArticle/Header';
import LongArticleSectionHeader from '../components/LongArticle/LongArticleSectionHeader';
import AuthorSection from '../components/LongArticle/AuthorSection';
import LongArticleSection from '../components/LongArticle/LongArticleSection';
import RichText from '../components/LongArticle/RichText';
import BlockPoints from '../components/LongArticle/BlockPoints';
import AccordionPoints from '../components/LongArticle/AccordionPoints';
import StepsWithBigImage from '../components/LongArticle/StepsWithBigImage';
import DocumentWithInfo from '../components/LongArticle/DocumentWithInfo';
import CustomHtml from '../components/LongArticle/CustomHtml';
import HighlightBox from '../components/ui/Slices/HighlightBox';
import '../styles/pages/long-article/long-article.scss';
import Layout from '../components/layout';
import { SLICE_TYPE } from '../constants/enum';
import Breadcrumbs from '../components/LongArticle/Breadcrumbs';
import LongArticleToc from '../components/LongArticle/LongArticleToc';
import {
  getH2AndH3HeadingsFromContainerClass,
  addNoFollowToAllLinksWhichContainNoFollowTag,
  scrollToSource
} from '../helper/helpers';
import RelatedArticles from '../components/LongArticle/RelatedArticles';
import SEO from '../components/seo';
import LinkCta from '../components/blog/LinkCta';

function longArticleTemplate({ data }) {
  const [headings, setHeadings] = useState([]);
  const longArticlePrismicData = data?.prismicLongArticle?.data;

  useEffect(() => {
    const newNestedHeadings = getH2AndH3HeadingsFromContainerClass('.long-article-container');
    addNoFollowToAllLinksWhichContainNoFollowTag();
    setHeadings(newNestedHeadings);
  }, []);

  useEffect(() => {
    scrollToSource();
  }, []);
  return (
    <Layout pageClassName="long-article-container">
      {longArticlePrismicData?.body?.map((articleDetail) => {
        switch (articleDetail?.slice_type) {
          case SLICE_TYPE?.BREADCRUMBS:
            return <Breadcrumbs content={articleDetail} />;
        }
      })}
      <SEO
        description={longArticlePrismicData?.seo_description?.text}
        keywords={
          longArticlePrismicData?.seo_focus_keywords?.text
            ? longArticlePrismicData?.seo_focus_keywords?.text?.split(',')
            : []
        }
        title={longArticlePrismicData?.seo_title?.text}
        metaImage={longArticlePrismicData?.header_image?.url}
        preLoadHeaderImage={longArticlePrismicData?.header_image?.url}
        type="blog"
        faqData={longArticlePrismicData?.seo_faq}
      />
      <Header
        title={longArticlePrismicData?.title?.text}
        publishedData={longArticlePrismicData?.published_date}
        timeToRead={longArticlePrismicData?.time_to_read?.text}
        headerImage={longArticlePrismicData?.header_image}
      />
      <AuthorSection
        leftSideData={longArticlePrismicData?.author_left_side_content?.html}
        authorDetails={longArticlePrismicData}
      />
      {headings && <LongArticleToc tocData={headings} />}

      {longArticlePrismicData?.body?.map((articleDetail) => {
        switch (articleDetail?.slice_type) {
          case SLICE_TYPE?.LONG_ARTICLE_SECTION_HEADER:
            return <LongArticleSectionHeader headerData={articleDetail?.primary} />;
          case SLICE_TYPE?.LONG_ARTICLE_SECTION:
            return <LongArticleSection sectionData={articleDetail?.primary} />;
          case SLICE_TYPE?.RICH_TEXT:
            return <RichText content={articleDetail?.primary} />;
          case SLICE_TYPE?.LONG_ARTICLE_BLOCK_POINTS:
            return <BlockPoints content={articleDetail?.items} />;
          case SLICE_TYPE?.ACCORDION_POINTS:
            return <AccordionPoints content={articleDetail?.items} primaryContent={articleDetail?.primary} />;
          case SLICE_TYPE?.LONG_ARTICLE_STEPS_WITH_BIG_IMAGE:
            return <StepsWithBigImage content={articleDetail?.items} />;
          case SLICE_TYPE?.LONG_ARTICLES_DOCUMENTS_WITH_INFO:
            return <DocumentWithInfo documentContent={articleDetail?.items} />;
          case SLICE_TYPE?.LONG_ARTICLE_CUSTOM_HTML:
            return <CustomHtml content={articleDetail?.primary} />;
          case SLICE_TYPE.HIGHLIGHT_BOX:
            return <HighlightBox highlightBoxData={articleDetail?.primary} />;
          case SLICE_TYPE.LONG_ARTICLE_RELATED_ARTICLES:
            return <RelatedArticles relatedArticles={articleDetail} />;
          case SLICE_TYPE.LINK_CTA:
            return <LinkCta slice={articleDetail} />;
          default:
            null;
        }
      })}
    </Layout>
  );
}

export default longArticleTemplate;
