import React from 'react';
import Header from '../components/company-sec-detail-page/Header';
import Layout from '../components/layout';
import '../styles/pages/company-sec-detail.scss';
import { graphql } from 'gatsby';
import CompanyDetails from '../components/company-sec-detail-page/CompanyDetails';
import SEO from '../components/seo';
import PropTypes from 'prop-types';
import { noFollowChecker } from '../helper/helpers';
export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicReviews(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        avearge {
          rating
        }
        link_banner {
          document {
            ... on PrismicBanner {
              uid
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        company_logo {
          alt
          url
        }
        description {
          text
        }
        email {
          url
        }
        language {
          text
        }
        long_description {
          text
          html
        }
        phone_number {
          text
        }
        servicelist {
          text
        }
        title {
          text
        }
        website {
          url
        }
        company_address {
          text
        }
        company_website {
          text
        }
        custom_average
        header_image {
          alt
          url
        }
        customer_review {
          rating_value
          review_description {
            text
          }
          customer_name {
            text
          }
          published_on(fromNow: true)
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
      }
      id
      uid
      lang
      last_publication_date
    }
  }
`;
export default function Reviews({ data }) {
  const reviewData = data?.prismicReviews;
  const reviewDetails = reviewData?.data;
  const countReducer = (accumulator, currentValue) => accumulator + currentValue;
  const totalCount = reviewDetails?.avearge?.filter((review) => !isNaN(review.rating)).length;
  let totalRating;
  if (reviewDetails?.avearge?.length > 0) {
    totalRating = reviewDetails?.avearge
      .map((review) => {
        if (!isNaN(review.rating)) {
          return review.rating;
        }
      })
      .reduce(countReducer);
  } else {
    totalRating = 0;
  }
  let averageScore = reviewDetails?.custom_average
    ? reviewDetails?.custom_average
    : Math.round((totalRating / totalCount) * 10) / 10;
  return (
    <Layout
      fullwidth={true}
      showWhitePaperFooter={true}
      showAboutStatrysFooter={true}
      showCustomerReviewsFooter={false}
      hideCta={true}
      bannerData={reviewDetails?.link_banner?.document}
    >
      <SEO
        description={
          averageScore
            ? `${reviewDetails?.title?.text} score is ${averageScore} from ${
                averageScore ? totalCount : 0
              } reviews by Statrys clients.`
            : `${reviewDetails?.title?.text} has not been reviewed yet by any Statrys client.`
        }
        title={`${reviewData?.data?.title?.text} - Read Customer Reviews`}
        companySecName={reviewDetails?.title?.text}
        ratingCount={totalCount}
        ratingValue={averageScore}
        noIndex={noFollowChecker(reviewData)}
      />
      <div className="bg-customBg-mainBg">
        <div className="max-w-6xl mx-auto px-4 md:px-0">
          <Header
            reviewData={reviewData}
            totalCount={totalCount}
            averageScore={averageScore}
            totalRating={totalRating}
          />
          <CompanyDetails reviewData={reviewData} />
        </div>
      </div>
    </Layout>
  );
}

Reviews.propTypes = {
  data: PropTypes.any
};
