import React, { useState } from 'react';
import Layout from '../components/layout';
import BaseText from '../components/ui/typography/BaseText';
import ClaimHeaderText from '../components/ui/typography/ClaimHeaderText';
import IconLinkedIn from '../images/icons/computer-logo-linkedin.svg';
import IconEmailAction from '../images/icons/email-action-at.svg';
import { Link } from 'gatsby';
import { slice } from 'lodash';
import SEO from '../components/seo';
import H1HeaderText from '../components/ui/typography/H1HeaderText';
import LatestBlogCard from '../components/blog/LatestBlogCard';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import { SHOW_NUMBER_OF_BLOGS } from '../constants/enum';
import PaypalCta from '../components/paypal-calculator/PaypalCta';

export default function userTemplate({ data }) {
  const [index, setIndex] = useState(SHOW_NUMBER_OF_BLOGS.EIGHT);
  const [isCompleted, setIsCompleted] = useState(false);
  const { avatar, full_name, role, title, bio, education, linkedin, email } = data.prismicUser.data;
  let categoryBlogs = data.allPrismicBlog.nodes.filter(
    (blog) => blog.data?.author?.document?.data?.full_name?.text == full_name.text
  );

  const loadMore = () => {
    setIndex(index + SHOW_NUMBER_OF_BLOGS.EIGHT);
    if (index >= categoryBlogs.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };
  const initialPosts = slice(categoryBlogs, 0, index);
  return (
    <Layout
      fullwidth={true}
      hideCta={true}
      changePreFooterImg={true}
      showCustomerReviewsFooter={false}
      hidePreFooter={true}
      isMainContainerRequired={true}
      isHomePage={true}
      bannerData={data?.prismicUser?.data?.link_banner?.document}
    >
      <SEO keywords={[`Statrys`]} title={full_name.text} />
      <div className="flex items-center mb-8 mt-6 gap-3">
        <Link to="/about-us">
          <div className={`text-xl text-light letter-spacing-1`}>About us</div>
        </Link>
        <div className={`bg-coral-500 h-2 w-2 rounded-full `}></div>
        <div className={`text-xl text-bold letter-spacing-1`}>{full_name.text}</div>
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div>
          <div className="flex items-end justify-start overflow-hidden user-img-container">
            <img src={avatar.url} alt="team image" className="user-img" width="340" height="340" loading="eager" />
          </div>
          <div className="md:block hidden mt-12">
            {education.map((details, index) => {
              return (
                <div className="mb-6" key={index}>
                  <BaseText title={details.place_name.text} className="place-name" />
                  <BaseText title={details.year.text} className="place-name" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="md:w-4/6">
          <H1HeaderText className="md:mt-0 mt-12 user-name" textColor="text-gray-300" title={full_name.text} />
          <ClaimHeaderText className="mt-2" fontWeight="text-light" title={role.text} />
          <div className="flex gap-2 mt-6">
            <a href={email?.url}>
              <img src={IconEmailAction} className="h-6 w-6" alt="emil icon" />
            </a>
            <a href={linkedin.url} target="_blank" rel="noopener noreferrer">
              <img src={IconLinkedIn} className="h-6 w-6" alt="linkedin icon" />
            </a>
          </div>
          <ClaimHeaderText className="my-12" fontWeight="text-light" title={title.text} />
          <BaseText
            className="mt-2 user-bio"
            textColor="text-gray-300"
            title={<div dangerouslySetInnerHTML={{ __html: bio.html }}></div>}
          />
          <div className="block md:hidden mt-12">
            {education.map((details, index) => {
              return (
                <div className="mb-6" key={index}>
                  <BaseText title={details.place_name.text} />
                  <BaseText title={details.year.text} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {initialPosts.length > 0 ? (
        <div>
          <ClaimHeaderText
            className="mt-16 mb-8 latest-blog-line"
            fontWeight="text-bold"
            textColor="text-charcoal-dark"
            title={`Latests posts by ${full_name.text}`}
          />
          <div className="flex flex-wrap gap gap-6">
            {initialPosts.map((blog, index) => {
              return (
                <div key={index} className="mb-8">
                  <LatestBlogCard data={blog} />
                </div>
              );
            })}
          </div>
          {!isCompleted && categoryBlogs.length > index && (
            <div className="flex justify-center">
              <PrimaryButton
                bgColor="text-white"
                color="text-coral-500"
                caption={`Load more articles`}
                onClick={loadMore}
              />
            </div>
          )}
        </div>
      ) : null}
      <div className="flex justify-start">
        <BaseText
          title={`85% of our customers open their \naccount in less than 3 days`}
          className="md:whitespace-pre-line content-main-heading mt-32"
          fontWeight="text-bold"
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full">
          <PaypalCta />
        </div>
      </div>
    </Layout>
  );
}
