import { linkResolver } from './link-resolver';
import BlogTemplate from '../../src/templates/blogTemplate';
import LongArticleTemplate from '../../src/templates/longArticleTemplate';
import GuidesArticle from '../../src/templates/guidesArticle';
import GuidesSection from '../../src/templates/guidesSection';
import CountryGuides from '../../src/templates/countryGuide';
import FaqArticles from '../../src/templates/faqArticles';
import User from '../../src/templates/user';
import UseCase from '../../src/templates/useCase';
import Rewards from '../../src/templates/rewards';
import Reviews from '../../src/templates/reviews';
import Resource from '../../src/templates/resource';
import TermsAndConditions from '../../src/templates/termsAndConditions';
import FaqArticlesCategory from '../../src/templates/faqArticlesCategoryTemplate';
/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: `${process.env.GATSBY_PRISMIC_REPO_NAME}`,
    linkResolver,
    componentResolver: {
      blog: BlogTemplate,
      article: GuidesArticle,
      long_article: LongArticleTemplate,
      'silos-section': GuidesSection,
      silos: CountryGuides,
      faq_articles: FaqArticles,
      faq_articles_category: FaqArticlesCategory,
      user: User,
      use_case: UseCase,
      terms_and_conditions: TermsAndConditions,
      rewards: Rewards,
      reviews: Reviews,
      resources: Resource
    }
  }
];
