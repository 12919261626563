import React from 'react';
import { graphql, navigate, Link } from 'gatsby';
import '../styles/pages/guides-section.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../styles/pages/silos-section.scss';
import '../styles/pages/faq-article.scss';
import './post.scss';
import { MUI_COLORS } from '../constants/enum';
import PropTypes from 'prop-types';
import FaqSupport from '../components/faq/FaqSupport';
import SearchInput from '../components/ui/form/SearchInput';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import FaqCategoryDropDown from '../components/ui/dropdowns/FaqCategoryDropDown';

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicFaqArticlesCategory(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        faq_product_category {
          uid
          document {
            ... on PrismicFaqsProductCategory {
              id
              data {
                category_title {
                  text
                }
              }
            }
          }
        }
        body {
          ... on PrismicFaqArticlesCategoryDataBodyLinkFaqsSubCategory {
            id
            primary {
              link_faqs_sub_category {
                document {
                  ... on PrismicFaqsSubCategory {
                    id
                    data {
                      sub_category_title {
                        text
                      }
                    }
                    uid
                  }
                }
              }
            }
            slice_type
            items {
              faqs_article_link {
                uid
                document {
                  ... on PrismicFaqArticles {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                      tag_caption
                      tag_background_color
                      category {
                        document {
                          ... on PrismicFaqArticlesCategory {
                            id
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        featured_image {
          url
          alt
        }

        section_title {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        silos_section_faq {
          answer {
            text
            html
          }
          question {
            text
          }
        }
        link_banner {
          document {
            ... on PrismicBanner {
              uid
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        author {
          document {
            ... on PrismicUser {
              id
              uid
              data {
                avatar {
                  url
                  gatsbyImageData(height: 64, width: 64)
                  alt
                }
                bio {
                  html
                }
                full_name {
                  text
                }
                role {
                  text
                }
                linkedin {
                  url
                }
                email {
                  url
                }
              }
            }
          }
        }
        floating_cta_button_caption {
          text
        }
        floating_cta_background_color
        floating_cta_heading_color
        floating_cta_subtitle_color
        floating_cta_button_link {
          url
        }
        floating_cta_image {
          url
        }
        floating_cta_subtitle {
          text
        }
        floating_cta_title {
          text
        }
      }
      id
      uid
      lang
      first_publication_date
      last_publication_date
    }
  }
`;
const navigateToSearchResults = (searchTerm) => {
  navigate('/faq-search', { state: { searchTerm } });
};
function GuidesSection({ data }) {
  let guide = data?.prismicFaqArticlesCategory;

  const articleSchemaData = {
    datePublished: guide?.first_publication_date,
    dateModified: guide?.last_publication_date,
    title: guide?.data?.title?.text,
    author: [
      {
        '@type': 'Person',
        name: guide?.data?.author?.document?.data?.full_name?.text,
        url: `${process.env.SITEURL}/team/${guide?.data?.author?.document?.uid}`
      }
    ]
  };
  return (
    <Layout
      customNavigationBg={MUI_COLORS.EXTRA_LIGHT_GRAY}
      fullwidth={true}
      showCustomerReviewsFooter={false}
      bannerData={guide?.data?.link_banner?.document}
    >
      <SEO
        description={guide?.data?.seo_description.text}
        title={guide?.data?.seo_title.text}
        metaImage={guide?.data?.seo_image?.url}
        faqData={guide?.data?.silos_section_faq}
        articleSchemaData={articleSchemaData}
      />
      <div className="bg-customBg-mainBg py-12">
        <div className=" max-w-6xl md:px-0 px-4 mx-auto">
          <div className="flex md:flex-row flex-col items-center gap-3">
            <div className="md:w-4/5 w-full">
              <div className=" flex items-center gap-3">
                <Link to="/faq">
                  <div className={`text-2xl text-light letter-spacing-1`}>FAQs</div>
                </Link>
                <div className={`bg-coral-500 h-2 w-2 rounded-full `}></div>
                <Link to="/faq" className={`text-2xl text-bold letter-spacing-1 `}>
                  {guide?.data?.faq_product_category?.document?.data?.category_title?.text}
                </Link>
              </div>
              <h1 className={`text-5xl text-bold letter-spacing-1 mt-4`}>{guide?.data?.section_title?.text}</h1>
            </div>
            <div className="w-2/5 md:block hidden">
              <SearchInput onSearch={(value) => navigateToSearchResults(value)} isFaq />
            </div>
          </div>
          <div className="mt-10">
            {guide?.data?.body.map((faqData) => (
              <FaqCategoryDropDown
                key={faqData?.uid}
                categoryUid={faqData?.uid}
                categoryTitle={faqData?.primary?.link_faqs_sub_category?.document?.data?.sub_category_title?.text}
                faqsList={faqData?.items}
              />
            ))}
          </div>
        </div>
      </div>

      <FaqSupport />
    </Layout>
  );
}

export default withPrismicPreview(GuidesSection);

GuidesSection.propTypes = {
  data: PropTypes.any,
  date: PropTypes.any,
  children: PropTypes.any
};
