import * as actionTypes from './actionTypes';

let initialState = {
  loginSsoUser: {},
  ssoUserInfo: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SSO_USER_START:
      return { ...state, loading: true };
    case actionTypes.LOGIN_SSO_USER_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.LOGIN_SSO_USER_SUCCESS:
      return { ...state, loginSsoUser: { ...action.loginSsoUser }, loading: false, error: false };

    case actionTypes.GET_SSO_USER_INFO_START:
      return { ...state, loading: true };
    case actionTypes.GET_SSO_USER_INFO_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_SSO_USER_INFO_SUCCESS:
      return { ...state, ssoUserInfo: { ...action.ssoUserInfo }, loading: false, error: false };

    default:
      return state;
  }
};
export default reducer;
