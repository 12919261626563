import React from 'react';
import Header from '../components/rewards-template/Header';
import Layout from '../components/layout';
import '../styles/pages/home.scss';
import { graphql } from 'gatsby';
import ExclusiveBenefits from '../components/rewards/ExclusiveBenefits';
import RewardSection from '../components/rewards-template/RewardSection';
import SEO from '../components/seo';
export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicRewards(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        partner_logo {
          alt
          url
        }
        link_banner {
          document {
            ... on PrismicBanner {
              uid
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        description {
          text
        }
        offers {
          text
        }
        title {
          text
        }
        reward_description {
          html
        }
        reward_header_image {
          alt
          url
        }
        reward_image {
          alt
          url
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
      id
      uid
      lang
    }
  }
`;
export default function rewards({ data }) {
  const rewardsData = data.prismicRewards.data;
  return (
    <Layout bannerData={rewardsData?.link_banner?.document}>
      <SEO title={rewardsData?.seo_title?.text} description={rewardsData?.seo_description?.text} />
      <Header rewardsData={rewardsData} />
      <RewardSection rewardsData={rewardsData} />
      <div className="mt-28">
        <ExclusiveBenefits title="How it works" />
      </div>
    </Layout>
  );
}
