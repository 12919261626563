import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Cross from '../../images/icons/customSvg/cross';
import BaseText from '../ui/typography/BaseText';
import { MUI_COLORS } from '../../constants/enum';

export default function Banner({ onCloseClick, bannerInfo }) {
  const query = graphql`
    query GenericBanner {
      prismicBanner(uid: { eq: "generic-banner" }) {
        data {
          background_color
          link {
            url
            uid
          }
          text_color
          link_text_background_color
          link_text {
            text
          }
          title {
            text
          }
        }
        uid
      }
    }
  `;

  const handleBannerClick = (e, bannerData, bannerUid) => {
    e.preventDefault(); // Prevent immediate redirection
    if (!bannerData) return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'top_banner_click',
      banner: {
        id: bannerUid,
        title: bannerData?.title?.text,
        destination_url: bannerData?.link?.url,
        source_url: window?.location?.href
      }
    });
    setTimeout(() => {
      window.location.href = bannerData?.link?.url;
    }, 300);
  };

  const renderBannerContent = (data, uid, onClick) => {
    if (!data?.title?.text) return null;

    const {
      title,
      link,
      text_color: textColor,
      background_color: backgroundColor,
      link_text_background_color: linkTextBackgroundColor,
      link_text: linkText
    } = data;

    return (
      <div
        className="relative z-1 bg-coral-500 md:h-14 items-center px-5 flex justify-between"
        style={{ backgroundColor }}
        id={uid}
      >
        <div></div>
        <div className="flex items-center">
          <div className="py-[9px] pl-0 md:pl-[29.5px] flex gap gap-1 relative">
            <BaseText
              title={
                <div style={{ color: textColor }}>
                  {title.text}
                  {link?.url && (
                    <a
                      href={link.url}
                      className={`text-bold cursor-pointer rounded-lg hover:text-current hover:text-underline ${
                        linkTextBackgroundColor ? 'px-2 py-1 text-white ml-4 leading-8' : 'ml-1'
                      }`}
                      style={{ backgroundColor: linkTextBackgroundColor }}
                      onClick={(e) => onClick(e, data, uid)}
                    >
                      {linkText?.text}
                    </a>
                  )}
                </div>
              }
            />
          </div>
        </div>
        <div onClick={onCloseClick} className="cursor-pointer">
          <Cross stroke={MUI_COLORS.WHITE} />
        </div>
      </div>
    );
  };

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const genericBannerData = data?.prismicBanner?.data;
        const genericBannerUid = data?.prismicBanner?.uid;
        const bannerData = bannerInfo?.data;
        const bannerUid = bannerInfo?.uid;
        return (
          <>
            {renderBannerContent(bannerData, bannerUid, handleBannerClick) ||
              renderBannerContent(genericBannerData, genericBannerUid, handleBannerClick)}
          </>
        );
      }}
    />
  );
}

Banner.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  bannerInfo: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string
      }),
      link: PropTypes.shape({
        url: PropTypes.string
      }),
      text_color: PropTypes.string,
      background_color: PropTypes.string,
      link_text_background_color: PropTypes.string,
      link_text: PropTypes.shape({
        text: PropTypes.string
      })
    }),
    uid: PropTypes.string
  })
};

Banner.defaultProps = {
  bannerInfo: null
};
