import { COMPANY_REGISTRATION_COUNTRY } from '../constants/enum';

export const ENDPOINTS = {
  COMPANY_REGISTRATION: `${process.env.INCORPORATION_SITE_URL}/signup/start`,
  HONG_KONG_COMPANY_REGISTRATION: `${process.env.INCORPORATION_SITE_URL}/signup/start?country=${COMPANY_REGISTRATION_COUNTRY.HONG_KONG}`,
  SINGAPORE_COMPANY_REGISTRATION: `${process.env.INCORPORATION_SITE_URL}/signup/start?country=${COMPANY_REGISTRATION_COUNTRY.SINGAPORE}`,
  HONG_KONG_LANDING_PAGE: '/hong-kong-company-registration',
  SINGAPORE_LANDING_PAGE: '/singapore-company-registration',
  OPEN_ACCOUNT: '/signup/getstarted',
  BUSINESS_OPEN_ACCOUNT: '/onboarding/signupStart',
  INTEGRATION_XERO: '/integration/xero',
  SELECT_MANAGER: `${process.env.ONBOARDING_BASE_URL}/onboarding/selectManager`,
  PRICING: '/pricing',
  INVOICES: '/invoicing-software',
  INVOICES_GENERATOR: '/tools/invoice-generator',
  BUSINESS_ACCOUNT: '/business-account',
  INTERNATIONAL_AND_LOCAL_PAYMENTS: '/international-and-local-payments',
  PAYMENT_CARD: '/uk/payment-card',
  FOREX: '/forex',
  XERO: '/integration/xero',
  BUSINESS_BANK_ACCOUNTS: '/reviews/business-bank-accounts',
  MOBILE_APP: '/mobile-app',
  LOGOUT: '/logout',
  TEAM: '/team',
  COMPARISONS: '/comparisons',
  UNDER_MAINTENANCE: '/under-maintenance',
  GUIDES_VIDEOS: '/guides/videos',
  OFFSHORE_ELIGIBILITY_QUIZ: '/offshore-eligibility-quiz',
  CHANGE_COMPANY_SECRETARY: '/change-company-secretary',
  CONTACT: '/contact',
  ACCOUNT_SAFETY_TIPS: '/account-safety-tips',
  LOGIN_V2: '/login-v2',
  ENABLE_MFA_SSO: '/enable-mfa-sso',
  DASHBOARD_V2: '/dashboard-v2',
  MY_PROFILE: '/my-profile',
  RESET_PASSWORD: '/reset-password',
  ONBOARDING_URL: '/signup/getstarted?=jurisdiction'
};
