import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GuidesTemplate from './guidesTemplate';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

export const query = graphql`
  query ($uid: String, $lang: String, $section_category: String) {
    allPrismicArticle(limit: 4, filter: { data: { section_category: { uid: { eq: $section_category } } } }) {
      nodes {
        _previewable
        data {
          title {
            text
          }
          published_date
          header_image {
            url
            alt
            thumbnails {
              small {
                alt
                url
              }
            }
          }
          linked_blog {
            document {
              ... on PrismicArticle {
                id
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          section_category {
            uid
            document {
              ... on PrismicSilosSection {
                data {
                  title {
                    text
                  }
                  custom_url {
                    text
                  }
                }
                id
                uid
              }
            }
          }
          silos_category {
            uid
            document {
              ... on PrismicSilos {
                data {
                  section_and_article_image {
                    alt
                    url
                  }
                }
                id
                uid
              }
            }
          }
        }

        id
        uid
        lang
      }
    }
    prismicArticle(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        blog_header_background_color
        blog_header_text_color
        content {
          html
          richText
        }
        link_banner {
          document {
            ... on PrismicBanner {
              uid
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        silos_category {
          document {
            ... on PrismicSilos {
              id
              data {
                title {
                  text
                }
                section_and_article_image {
                  alt
                  url
                }
              }
              uid
            }
          }
        }
        section_category {
          document {
            ... on PrismicSilosSection {
              data {
                title {
                  text
                }
                subtitle {
                  text
                }
                custom_url {
                  text
                }
              }
              id
              uid
            }
          }
        }
        published_date
        header_image {
          url
          alt
          thumbnails {
            small {
              alt
              url
            }
          }
        }
        linked_blog {
          document {
            ... on PrismicArticle {
              id
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
        show_toc
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        podcast_link {
          html
          text
        }
        meta_image {
          url
        }
        time_to_read {
          text
        }
        author {
          document {
            ... on PrismicUser {
              id
              uid
              data {
                avatar {
                  url
                  gatsbyImageData(height: 64, width: 64)
                  alt
                  thumbnails {
                    mobile {
                      url
                      dimensions {
                        height
                        width
                      }
                    }
                  }
                }
                bio {
                  html
                  text
                }
                full_name {
                  text
                }
                role {
                  text
                }
                linkedin {
                  url
                }
                email {
                  url
                }
              }
            }
          }
        }

        body {
          ... on PrismicArticleDataBodyKeyTakeaways {
            id
            primary {
              key_takaways_title {
                html
                text
              }
              list_type
            }
            items {
              takeaways_points {
                text
              }
              takeaway_point_link {
                url
                target
              }
            }
            slice_type
          }

          ... on PrismicArticleDataBodyPolling {
            id
            slice_type
            primary {
              linked_polling {
                uid
                document {
                  ... on PrismicPolling {
                    id
                    data {
                      options {
                        option {
                          text
                        }
                        option_disable
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyHeadingText {
            id
            primary {
              bold
              heading_tag_type
              heading_text {
                text
              }
              number_field
              text_color
            }
            slice_type
          }
          ... on PrismicArticleDataBodyHighlightBox {
            id
            slice_type
            primary {
              box_icon {
                gatsbyImageData(width: 56)
                alt
              }
              box_title {
                text
                html
              }
              color
              copy_text
              twitter_share
            }
          }
          ... on PrismicArticleDataBodyCustomTable {
            id
            slice_type
            primary {
              column_four_width_percentage {
                html
                text
              }
              column_one_width_percentage {
                html
                text
              }
              column_three_width_percentage {
                html
                text
              }
              column_two_width_percentage {
                html
                text
              }
            }
            items {
              column_four {
                text
                html
              }
              column_four_image {
                alt
                url
              }
              column_one {
                text
                html
              }
              column_one_image {
                alt
                url
              }
              column_three {
                text
                html
              }
              column_three_image {
                alt
                url
              }
              column_two {
                text
                html
              }
              column_two_image {
                alt
                url
              }
              height_of_image
              width_of_image
              center_the_content
              is_heading
            }
          }
          ... on PrismicArticleDataBodyToggleableComponent {
            id
            slice_type
            primary {
              desciption {
                text
              }
              heading {
                text
              }
              icon1 {
                alt
                url
              }
              link {
                target
                uid
                url
              }
            }
          }
          ... on PrismicArticleDataBodyQuote {
            id
            primary {
              quote_text {
                html
              }
              url {
                url
              }
              linked_in_url {
                url
              }
              designation {
                text
              }
              author_name {
                text
              }
              author_image {
                alt
                url
              }
            }
            items {
              answer {
                html
              }
              question {
                text
              }
            }
            slice_type
          }
          ... on PrismicArticleDataBodyDoubleColumn {
            id
            primary {
              first_column {
                html
                text
              }
              second_column {
                html
                text
              }
            }
            slice_type
          }

          ... on PrismicArticleDataBodyImage {
            id
            primary {
              blog_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicArticleDataBodyEditorsNote {
            id
            primary {
              editors_note_description {
                html
              }
              editors_note_title {
                text
              }
              editors_note_author {
                document {
                  ... on PrismicUser {
                    id
                    uid
                    data {
                      avatar {
                        url
                        alt
                      }
                      bio {
                        html
                      }
                      full_name {
                        text
                      }
                      role {
                        text
                      }
                      linkedin {
                        url
                      }
                      email {
                        url
                      }
                    }
                  }
                }
              }
              more_info_label {
                text
              }
              more_info_content {
                html
              }
            }
            slice_type
          }
          ... on PrismicArticleDataBodyVideo {
            id
            primary {
              youtube_video {
                embed_url
                html
              }
            }
            slice_type
          }
          ... on PrismicArticleDataBodyLinkCta {
            id
            primary {
              linked_cta {
                document {
                  ... on PrismicCta {
                    id
                    data {
                      background_color
                      button_color
                      button_text_color
                      text_color
                      title_font_size
                      cta_button {
                        text
                        html
                      }
                      button_url {
                        url
                      }
                      cta_image {
                        alt
                        dimensions {
                          height
                          width
                        }
                        url
                      }
                      description1 {
                        text
                      }
                      title1 {
                        text
                      }
                      background_image {
                        alt
                        url
                      }
                      secondary_button {
                        text
                        html
                      }
                      secondary_button_color
                      secondary_button_url {
                        url
                        target
                      }
                      secondary_text_color
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicArticleDataBodyBlogCta {
            id
            primary {
              background_color
              button_color
              button_text_color
              text_color
              title_font_size
              cta_button {
                text
                html
              }
              button_url {
                url
              }
              cta_image {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              description1 {
                text
              }
              title1 {
                text
              }
              background_image {
                alt
                url
              }
              secondary_button {
                text
                html
              }
              secondary_button_color
              secondary_button_url {
                url
                target
              }
              secondary_text_color
            }
            slice_type
          }

          ... on PrismicArticleDataBodyPodcast {
            id
            slice_type
            primary {
              podcast_id {
                text
              }
              script_link {
                url
              }
            }
          }
          ... on PrismicArticleDataBodyRichText {
            id
            slice_type
            primary {
              page_content {
                html
              }
            }
          }
          ... on PrismicArticleDataBodyTable {
            id
            slice_type
            primary {
              is_table_full_width
              table_data {
                text
              }
            }
          }
          ... on PrismicArticleDataBodyButton {
            slice_type
            primary {
              button_link {
                url
              }
              button_title {
                text
              }
            }
          }
          ... on PrismicArticleDataBodyQuestionAndAnswer {
            primary {
              qa_answer {
                text
                html
              }
              qa_number
              qa_question {
                text
              }
            }
            slice_type
          }
          ... on PrismicArticleDataBodyForexLinkComponent {
            slice_type
            primary {
              forex_link_description {
                text
                html
              }
              forex_link_image {
                url
              }
              forex_link_title {
                text
              }
              forex_link_url {
                url
              }
            }
          }
          ... on PrismicArticleDataBodyYoutubeVideo {
            id
            primary {
              youtube_link {
                url
              }
            }
            slice_type
          }
        }
        seo_description {
          text
        }
        seo_focus_keywords {
          text
        }
        seo_title {
          text
        }
        seo_faq {
          question {
            html
            text
          }
          answer {
            html
            text
          }
        }
        video_schema {
          text
        }
        review_description {
          text
        }
        review_star_rating {
          url
          alt
        }
        review_title {
          text
        }
        reviewer_location {
          text
        }
        show_promo_header
        long_table_of_content
        card_button_caption {
          text
        }
        sources {
          source_list_link {
            url
          }
          sources_list {
            text
          }
          is_source_link
        }
        disclaimer_description {
          html
        }
        disclaimer_more_info_content {
          html
        }
        disclaimer_more_info_label {
          text
        }
        card_button_link {
          url
        }
        card_points {
          card_list {
            text
          }
          show_currencies
        }
        card_title {
          text
        }
        blog_floating_related_articles_list {
          related_aticle {
            uid
            type
            document {
              ... on PrismicArticle {
                id
                data {
                  section_category {
                    uid
                    document {
                      ... on PrismicSilosSection {
                        data {
                          title {
                            text
                          }
                          custom_url {
                            text
                          }
                        }
                        id
                        uid
                      }
                    }
                  }
                  silos_category {
                    uid
                    document {
                      ... on PrismicSilos {
                        data {
                          section_and_article_image {
                            alt
                            url
                          }
                        }
                        id
                        uid
                      }
                    }
                  }
                  title {
                    text
                  }
                  header_image {
                    url
                    alt
                    thumbnails {
                      small {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              ... on PrismicSilosSection {
                id
                uid
                data {
                  header_image {
                    url
                    alt
                  }
                  title {
                    text
                  }
                  custom_url {
                    text
                  }
                  silos_category {
                    uid
                    document {
                      ... on PrismicSilos {
                        id
                        data {
                          section_and_article_image {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                uid
              }
            }
          }
        }
      }
      last_publication_date
      uid
    }
  }
`;

function GuidesArticle({ data }) {
  return <GuidesTemplate articleData={data?.prismicArticle} allPrismicArticleList={data.allPrismicArticle} />;
}

GuidesArticle.propTypes = {
  data: PropTypes.any,
  date: PropTypes.any
};

export default withPrismicPreview(GuidesArticle);
