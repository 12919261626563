export const CLIENT_APPLICATION_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING_REVIEW: 'PENDING_REVIEW',
  APPROVING: 'APPROVING'
};

export const PROSPECT_APPLICATION_PROCESS_STATUS = {
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE'
};

export const PROSPECT_APPLICATION_COMPLIANCE_STATUS = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  APPROVED: 'APPROVED',
  APPROVING: 'APPROVING'
};

export const CLIENT_APPLICATION_STATUS_DATA = [
  { status: 'UNDER_REVIEW', displayValue: 'Under Review', backgroundColor: '#e6f2f1' },
  { status: 'UNFINISHED', displayValue: 'Unfinished Application', backgroundColor: '#f9dbd1' }
];
