import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import UserTemplate from './userTemplate';
import '../styles/pages/users/users.scss';

export const query = graphql`
  query ($uid: String, $lang: String) {
    allPrismicBlog(filter: { lang: { eq: $lang } }, sort: { data: { published_date: DESC } }) {
      nodes {
        _previewable
        data {
          title {
            text
          }
          custom_url {
            text
          }
          custom_url_without_blog {
            text
          }
          published_date
          header_image {
            url
            alt
          }
          category {
            document {
              ... on PrismicCategory {
                id
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          author {
            document {
              ... on PrismicUser {
                id
                uid
                data {
                  full_name {
                    text
                  }
                }
              }
            }
          }
        }
        id
        uid
        lang
      }
    }
    prismicUser(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        full_name {
          text
        }
        link_banner {
          document {
            ... on PrismicBanner {
              uid
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        role {
          text
        }
        linkedin {
          url
        }
        email {
          url
        }
        education {
          place_name {
            text
          }
          year {
            text
          }
        }
        avatar {
          url
          alt
        }
        title {
          text
        }
        bio {
          text
          html
        }
      }
      id
      uid
      lang
    }
  }
`;

function user({ data }) {
  return <UserTemplate data={data} />;
}

user.propTypes = {
  data: PropTypes.any
};

export default user;
