import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BaseText from '../components/ui/typography/BaseText';
import '../styles/pages/country-guide.scss';
import SEO from '../components/seo';
import '../styles/pages/silos/silos.scss';
import PropTypes from 'prop-types';
import Header from '../components/silos/Header';
import SilosArticleSection from '../components/silos/SilosArticleSection';
import { Link as ScrollLink } from 'react-scroll';
import DynamicCtaTemplate from '../components/common/DynamicCtaTemplate';
import HeadphoneCustomer from '../images/icons/customSvg/headphoneCustomer.js';
import CreditCard from '../images/icons/customSvg/creditCard';
import CloudWifi from '../images/icons/customSvg/cloudWifi.js';
import businessAccountCta from '../images/graphics/ctas/business-account-cta.png';
import CurrencyExchange from '../images/icons/customSvg/currencyExchange.js';
import GiftBox from '../images/icons/customSvg/giftBox';
import Monitor from '../images/icons/customSvg/Monitor';
import Hammer from '../images/icons/customSvg/Hammer';
import CashPaymentBag from '../images/icons/customSvg/CashPaymentBag';
import RegistrationHeroImag from '../images/graphics/registerCompany/hkCta.png';
import SingaporeRegistrationHeroImag from '../images/graphics/registerCompany/guides-cta.png';

import PaypalCta from '../components/paypal-calculator/PaypalCta';
import { ENDPOINTS } from '../service/end-points.js';
export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicSilos(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        featured_article {
          linked_article {
            document {
              ... on PrismicSilosSection {
                id
                data {
                  header_image {
                    alt
                    url
                  }
                  meta_image {
                    alt
                    url
                  }
                  title {
                    text
                  }
                  custom_url {
                    text
                  }

                  silos_category {
                    document {
                      ... on PrismicSilos {
                        id
                        data {
                          section_and_article_image {
                            alt
                            url
                          }
                        }
                      }
                    }
                    uid
                  }
                }
                uid
              }

              ... on PrismicArticle {
                id
                data {
                  header_image {
                    alt
                    url
                  }
                  meta_image {
                    alt
                    url
                  }
                  title {
                    text
                  }
                  section_category {
                    document {
                      ... on PrismicSilosSection {
                        id
                        data {
                          custom_url {
                            text
                          }
                        }
                      }
                    }
                  }
                  linked_countries {
                    country {
                      uid
                    }
                  }
                  silos_category {
                    document {
                      ... on PrismicSilos {
                        id
                        data {
                          section_and_article_image {
                            alt
                            url
                          }
                        }
                      }
                    }
                    uid
                  }
                  section_category {
                    uid
                  }
                }
                uid
              }
            }
          }
        }
        article_category {
          related_category {
            document {
              ... on PrismicSilosSection {
                id
                data {
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  silos_category {
                    uid
                  }
                  custom_url {
                    text
                  }
                }
                uid
              }
            }
            uid
          }
        }
        title {
          text
        }
        link_banner {
          document {
            ... on PrismicBanner {
              uid
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        subtitle {
          text
        }
        card_title {
          text
        }
        card_subtitle {
          text
        }
        card_image {
          url
          alt
        }
        description {
          text
        }
        person_name {
          text
        }
        company_name {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          url
        }
        cta_button_caption {
          text
        }
        floating_cta_title {
          text
        }
        floating_cta_subtitle {
          text
        }
        floating_cta_image {
          url
          alt
        }
        body {
          ... on PrismicSilosDataBodyTable {
            id
            primary {
              table_data {
                html
                text
              }
            }
            slice_type
          }
        }
      }

      id
      uid
      lang
    }

    allPrismicArticle {
      nodes {
        data {
          title {
            text
          }
          section_category {
            uid
            document {
              ... on PrismicSilosSection {
                id
                data {
                  custom_url {
                    text
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
          header_image {
            alt
            url
          }
          silos_category {
            document {
              ... on PrismicSilos {
                id
                uid
                data {
                  section_and_article_image {
                    alt
                    url
                  }
                }
              }
            }
            uid
          }
        }
        id
        uid
        lang
      }
    }
    allPrismicSilosSection {
      edges {
        node {
          _previewable
          uid
          data {
            content {
              text
            }
            title {
              text
            }
            subtitle {
              text
            }
            description {
              text
            }
            published_date
            custom_url {
              text
            }
            silos_category {
              document {
                ... on PrismicSilos {
                  id
                  data {
                    title {
                      text
                    }
                    section_and_article_image {
                      alt
                      url
                    }
                  }
                }
              }
              uid
            }
            header_image {
              alt
              url
            }
            meta_image {
              alt
              url
            }
            author {
              document {
                ... on PrismicUser {
                  id
                  data {
                    avatar {
                      alt
                      url
                    }
                    full_name {
                      text
                    }
                    role {
                      text
                    }
                  }
                }
              }
              uid
            }
          }
        }
      }
    }
  }
`;

const businessAccountCtaPoints = [
  {
    text: '11 Currencies in 1 account',
    icon: <CurrencyExchange />
  },
  {
    text: '100% online application form',
    icon: <CloudWifi />
  },
  {
    text: 'Highly rated customer support',
    icon: <HeadphoneCustomer />
  },
  {
    text: 'Physical and virtual cards',
    icon: <CreditCard />
  }
];

const ctaPoints = [
  {
    text: 'Everything you need in one package',
    icon: <GiftBox />
  },
  {
    text: '100% Online and hassle-free',
    icon: <Monitor />
  },
  {
    text: 'Get a business account + payment card + account manager with Statrys. (subject to approval) ',
    icon: <CashPaymentBag width="21" height="21" />
  }
];

const singaPoreCtaPoints = [
  {
    text: 'Everything you need in one package',
    icon: <GiftBox />
  },
  {
    text: '100% Online and hassle-free',
    icon: <Monitor />
  },
  {
    text: 'Nominee Director included',
    icon: <Hammer />
  }
];

const TYPE_OF_ARTICLES = {
  SINGAPORE: 'singapore',
  HONG_KONG: 'hong-kong'
};
export default function CountryGuide({ data }) {
  const [mainBlogLink, setMainBlogLink] = useState(false);
  let guide = data?.prismicSilos;
  let featuredArticle = data?.prismicSilos?.data?.featured_article;
  let articleCategory = data?.prismicSilos?.data?.article_category;
  let allPrismicSilosSection = data?.allPrismicSilosSection?.edges;
  let allPrismicArticle = data?.allPrismicArticle;

  const prismicCategorySilosSection = allPrismicSilosSection.filter((silosSection) => {
    return articleCategory.some((category) => category?.related_category?.uid === silosSection.node?.uid);
  });

  return (
    <Layout
      hideCta={true}
      changePreFooterImg={true}
      showCustomerReviewsFooter={false}
      hidePreFooter={true}
      isMainContainerRequired={true}
      blog={true}
      isHomePage={true}
      fullwidth={true}
      bannerData={guide?.data?.link_banner?.document}
    >
      <SEO
        description={guide?.data?.seo_description.text}
        title={guide?.data?.seo_title.text}
        metaImage={guide?.data?.seo_image?.url}
      />
      <Header uniqueCategoryBlog={featuredArticle} country={guide?.data?.title?.text} />
      <div className="flex flex-row flex-wrap mt-12">
        {articleCategory.map((eachCategory, index) => {
          return (
            <ScrollLink
              onMouseEnter={() => setMainBlogLink(index)}
              onMouseLeave={() => setMainBlogLink(null)}
              to={eachCategory?.related_category?.document?.data?.title?.text}
              key={index}
              spy={true}
              smooth={true}
              duration={500}
              offset={-110}
              className={`blog-category-button ${
                mainBlogLink === index ? 'bg-coral-500' : 'bg-salmon-400'
              }  mr-2 mb-2 cursor-pointer`}
            >
              <div className="flex gap-4 items-center py-2 px-4">
                <BaseText
                  title={
                    eachCategory?.related_category?.document?.data?.subtitle?.text
                      ? eachCategory?.related_category?.document?.data?.subtitle?.text
                      : eachCategory?.related_category?.document?.data?.title?.text
                  }
                  textColor={mainBlogLink === index ? 'text-white' : 'text-gray-500'}
                  fontWeight="text-medium"
                  lineHeight="leading-7"
                />
              </div>
            </ScrollLink>
          );
        })}
      </div>
      {prismicCategorySilosSection?.map((section, index, sectionArray) => {
        const respectiveArticle = allPrismicArticle?.nodes?.filter(
          (article) => article.data?.section_category?.uid === section?.node?.uid
        );
        return (
          <>
            {index === 0 && (
              <DynamicCtaTemplate
                title={'Open your Hong Kong business \naccount in 3 days.'}
                ctaPointsWithIcon={businessAccountCtaPoints}
                ctaContainerClass="bg-salmon-500 margin-top-72"
                bottomTextColor="text-charcoal-dark"
                image={businessAccountCta}
                urlLink={'/business-account'}
                buttonCaption="Check our business account"
                bgButtonColor="bg-coral-500"
                bottomTagText="100% Online and hassle-free"
                imageBackgroundColor="cta-blur-salmon-bg"
                imageStyleClass="adjust-image-invoice-position mt-auto"
                pointsTextColor="text-charcoal-dark"
              />
            )}
            {index === 1 && (
              <DynamicCtaTemplate
                title={'The Ultimate Guide to Company Registration in Hong Kong in 2025'}
                subHeading={'Start your business right way with our step-by-step guide.'}
                ctaContainerClass="bg-coral-500 margin-top-72 text-white"
                bottomTextColor="text-white"
                image={'https://images.prismic.io/statrys/Zz6wPa8jQArT1Gdq_Frame886.png?auto=format,compress'}
                urlLink={'/guides/hong-kong/company-registration-guide'}
                buttonCaption="Get Started"
                bgButtonColor="bg-white"
                imageBackgroundColor="cta-blur-salmon-bg"
                imageStyleClass="object-contain max-h-[360px] right-0 top-0 md:block hidden"
                pointsTextColor="text-charcoal-dark"
                buttonClass="md:w-48 w-full text-coral-500 text-medium"
                titleColor="text-white"
                subHeaderColor="text-white"
              />
            )}
            {sectionArray.length >= 3 && index === 3 && (
              <>
                {section?.node?.data?.silos_category?.uid === TYPE_OF_ARTICLES.HONG_KONG ? (
                  <DynamicCtaTemplate
                    title="Hong Kong Company Registration"
                    subHeading="One offer, One price, All included"
                    ctaPointsWithIcon={ctaPoints}
                    ctaContainerClass="bg-green-200 margin-top-72"
                    titleColor="text-green-600"
                    subHeaderColor="text-green-500"
                    bottomTextColor="text-charcoal-dark"
                    image={RegistrationHeroImag}
                    urlLink={'/hong-kong-company-registration/'}
                    buttonCaption="Take a look"
                    bgButtonColor="bg-green-500"
                    bottomTagText="100% Online and hassle-free"
                    imageBackgroundColor="guide-cr-cta-blur-bg"
                    imageStyleClass="md:bottom-0 bottom-auto md:w-[417px] w-auto right-10"
                    pointsTextColor="text-charcoal-dark"
                  />
                ) : (
                  <DynamicCtaTemplate
                    title="Singapore Company Registration"
                    subHeading="One offer, One price, All included"
                    ctaPointsWithIcon={singaPoreCtaPoints}
                    ctaContainerClass="bg-green-200 margin-top-72"
                    titleColor="text-green-600"
                    subHeaderColor="text-green-500"
                    bottomTextColor="text-charcoal-dark"
                    image={SingaporeRegistrationHeroImag}
                    urlLink={ENDPOINTS.SINGAPORE_LANDING_PAGE}
                    buttonCaption="Take a look"
                    bgButtonColor="bg-green-500"
                    bottomTagText="100% Online and hassle-free"
                    imageBackgroundColor="guide-cr-cta-blur-bg"
                    imageStyleClass="md:bottom-0 bottom-auto md:w-[417px] w-auto md:right-[60px]"
                    pointsTextColor="text-charcoal-dark"
                  />
                )}
              </>
            )}
            <div key={index}>
              <SilosArticleSection silosSection={section} articles={respectiveArticle} />
            </div>
          </>
        );
      })}
      <BaseText
        title={`85% of our customers open their \naccount in less than 3 days`}
        className="flex justify-start md:whitespace-pre-line content-main-heading mt-32"
        fontWeight="text-bold"
      />
      <div className="flex justify-center w-full">
        <PaypalCta />
      </div>
    </Layout>
  );
}

CountryGuide.propTypes = {
  data: PropTypes.any
};
