import {
  FILE_UPLOAD_TYPE,
  OWNER_DIRECTOR_TYPES,
  AVATAR_COlORS,
  API_ERROR_CODES,
  API_CODE,
  COUNTRY_CODE,
  ERROR_STATUS_CODE,
  REGEX,
  FILE_SIZE,
  REQUIRED_LENGTH,
  SPECIAL_CHARACTER_VALIDATION,
  MUI_COLORS,
  BLOG_UID,
  CHAR_CODE,
  POST_TYPE,
  DateFormats
} from '../constants/enum';
import { RESOURCES_HUBSPOT_INTEGRATION } from '../service/services';
import { navigate } from 'gatsby';
import { CLIENT_APPLICATION_STATUS } from '../constants/application-status';
import moment from 'moment';
import axios from 'axios';
import { getImageData } from 'gatsby-plugin-image';
import InvoiceApi from '../store/invoiceInterceptor';
import { ENDPOINTS } from '../service/invoice-service';

export const handleEnter = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
  if (
    event.target.value.length === 0 &&
    event.key.toLowerCase() === 'backspace' &&
    (event.target.name === 'month' || event.target.name === 'year')
  ) {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index - 1].focus();
    event.preventDefault();
  }
};

export const DisplayApplicationStatus = (status) => {
  if (status === CLIENT_APPLICATION_STATUS.PENDING_REVIEW || status === CLIENT_APPLICATION_STATUS.APPROVING) {
    return 'Under Review';
  }
};

export const companyAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.MSWORD ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.OFFICE_DOCUMENT) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};
export const stakeHolderAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};

export const stakeHolderOwnersCheck = (companyStakeHolder) => {
  if (
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.OWNER ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.NOMINEE_OWNER_DIRECTOR ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.NOMINEE_OWNER
  )
    return true;
  else return false;
};
const colors = AVATAR_COlORS;
export function getAvatarBackgroundColor(text) {
  const charCodes = text
    ? text
        ?.split('')
        ?.map((char) => char.charCodeAt(0))
        ?.join('')
    : '-';
  return colors[parseInt(charCodes, 10) % colors.length];
}

export const MatchingModalApiErrorStatus = (getApiError) => {
  if (
    (getApiError?.status === API_ERROR_CODES.STATUS_422 &&
      getApiError?.data?.code !== ERROR_STATUS_CODE.ONB_1038 &&
      getApiError?.data?.code !== ERROR_STATUS_CODE.ONB_1008) ||
    getApiError?.status === API_ERROR_CODES.STATUS_412 ||
    getApiError?.status === API_ERROR_CODES.STATUS_403 ||
    getApiError?.status === API_ERROR_CODES?.STATUS_402
  ) {
    return true;
  } else {
    return false;
  }
};

export const MatchingInvoiceApiErrorStatus = (getApiError) => {
  if (getApiError?.status === API_ERROR_CODES.STATUS_400 || getApiError?.status === API_ERROR_CODES.STATUS_500) {
    return true;
  } else {
    return false;
  }
};
export const MatchingOopsApiErrorStatus = (error) => {
  if (
    error?.response?.status !== API_ERROR_CODES.STATUS_422 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_412 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_401 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_402 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_403
  ) {
    return true;
  } else {
    return false;
  }
};

export const Matching401ApiErrorStatus = (error) => {
  if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
    return true;
  } else {
    return false;
  }
};

export const sendHubspotRequest = (final_data, hubspot_integration, isCompanyRegistrationWaitingFlow) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', hubspot_integration);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      if (!isCompanyRegistrationWaitingFlow) {
        navigate('/thankyou');
      }
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      console.log(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      console.log(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      console.log(xhr.responseText);
    }
  };
};

export const ConvertDateToDDMMMYYY = (date) => {
  return moment(date).format(DateFormats.DDMMMYYY);
};

export const setURLQueryParamsAndRedirectToPaymentApp = async () => {
  const auth = JSON.parse(sessionStorage.getItem('auth'));
  if (auth) {
    const clientCode = sessionStorage.getItem('clientCode') || '';
    const queryParams = `clientCode=${clientCode}&expiryTime=${auth.expiryTime}&code=${auth.token}&uuid=${auth.uuid}`;

    if (queryParams) {
      window.open(`${process.env.PAYMENT_APP_LOGIN}?${queryParams}`, '_self');
      sessionStorage.clear();
    }
  }
};

export const getCountryName = (countriesOptions, counrtyCode) => {
  let filterJurisdiction = countriesOptions.find((countryOption) => {
    if (countryOption[COUNTRY_CODE.ALPHA_2] == counrtyCode) {
      return countryOption?.name;
    }
  });
  return filterJurisdiction;
};

export const sendHubspotRequestResources = (final_data, downloadURI) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', RESOURCES_HUBSPOT_INTEGRATION.HUBSPOT_URL);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      downloadURI();
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      alert(xhr.responseText);
    }
  };
};

export function hubspotCookie() {
  var b = document.cookie?.match(REGEX.HUBSPOT_COOKIE_REGEX);
  return b ? b.pop() : '';
}

export const getLastPublishedDate = (publishedDate) => {
  const now = moment(new Date()); //todays date
  const end = moment(publishedDate); // another date
  const duration = moment.duration(now.diff(end));
  const secs = duration.asSeconds();
  if (secs < 60) {
    return Math.floor(secs) === 1 ? 'one second ago' : Math.floor(secs) + ' seconds ago';
  } else {
    const minutes = duration.asMinutes();
    if (minutes < 60) {
      return Math.floor(minutes) === 1 ? 'one minute ago' : Math.floor(minutes) + ' minutes ago';
    } else {
      const hours = duration.asHours();
      if (hours < 24) {
        return Math.floor(hours) === 1 ? 'an hour ago' : Math.floor(hours) + ' hours ago';
      } else {
        const days = duration.asDays();
        return Math.floor(days) === 1 ? 'Yesterday' : moment(publishedDate).format('DD/MM/YYYY');
      }
    }
  }
};

export const redirectUserToLogin = () => {
  location.replace('/login');
};
export async function fetchAppVersion() {
  try {
    let response = await axios.get('/app-version.html');
    let splittedAppVersion = response.data.toString().split('\n');
    let trimmedAppVersion = splittedAppVersion[0].trim();
    sessionStorage.setItem('app-version', trimmedAppVersion);
  } catch (error) {
    console.error(error);
  }
}

export const isUserLoggedIn = () => {
  let userLogin = sessionStorage.getItem('userLogin');
  let customerID = sessionStorage.getItem('customerID');
  let prospectCode = sessionStorage.getItem('prospectCode');
  if (!userLogin || !customerID || !prospectCode) {
    navigate('/login');
  } else {
    return true;
  }
};

export const isUserCodePresent = () => {
  let customerID = sessionStorage.getItem('customerID');
  if (!customerID) {
    navigate('/login');
  } else {
    return true;
  }
};
export const isProspectCodePresent = () => {
  let prospectCode = sessionStorage.getItem('prospectCode');
  if (!prospectCode) {
    return false;
  } else {
    return true;
  }
};

export const redirectToOopsPage = (error) => {
  navigate('/oops', {
    state: {
      message: error?.response?.data?.message,
      errorList: error?.response?.data?.items,
      apiErrorCode: error?.response?.data?.code,
      apiStatusCode: error?.response?.status
    }
  });
};

export const getChoppedDocumentName = (selectedDocs) => {
  let documentNameArray = selectedDocs[0]?.name?.split('.');
  let documentNameArrayLastElement = documentNameArray.pop();
  let modifiedDocumentName = documentNameArray.join('') + '.' + documentNameArrayLastElement;
  const [documentName, documentExt] = modifiedDocumentName.split('.');
  return [documentName.substring(0, REQUIRED_LENGTH.ALLOWED_DOCUMENT_LENGTH - 1 - documentExt.length), documentExt];
};

export const stringTruncate = (text, numberOfString) => {
  let finalString;
  if (text?.length > numberOfString) {
    finalString = text?.substring(0, numberOfString) + '...';
  } else {
    finalString = text;
  }
  return finalString;
};

export const makeFirstLetterCapitalize = (value) => {
  const capitalizedString = value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : '';
  return capitalizedString;
};

export const relatedArticleCustomUrl = (item) => {
  if (item?.type === POST_TYPE?.BLOG) {
    if (item?.document?.data?.custom_url?.text) return `/blog/${item?.document?.data?.custom_url?.text}/${item?.uid}`;
    if (item?.document?.data?.custom_url_without_blog?.text)
      return `/${item?.document?.data?.custom_url_without_blog?.text}/${item?.uid}`;
    else return `/blog/${item?.uid}`;
  }
  if (item?.type === POST_TYPE?.ARTICLE) {
    if (item?.document?.data?.section_category?.document?.data?.custom_url?.text)
      return `/guides/${item?.document?.data?.silos_category?.document?.uid}/${item?.document?.data?.section_category?.document?.data.custom_url?.text}/${item?.uid}`;
    else
      return `/guides/${item?.document?.data?.silos_category?.document?.uid}/${item?.document?.data?.section_category?.uid}/${item?.uid}`;
  }
  if (item?.type === POST_TYPE.SILOS_SECTION) {
    if (item?.document?.data?.custom_url?.text) {
      return `/guides/${item?.document?.data?.silos_category?.uid}/${item?.document?.data?.custom_url?.text}`;
    } else return `/guides/${item?.document?.data?.silos_category?.uid}/${item?.uid}`;
  } else {
    return `/guides/${item?.document?.silos_category?.uid}/${item?.uid}`;
  }
};
export const relatedGuidesCustomUrl = (item) => {
  console.log(item, 'itemh', item?.document?.type);
  if (item?.document?.type === POST_TYPE?.BLOG) {
    if (item?.document?.data?.custom_url?.text)
      return `/blog/${item?.document?.data?.custom_url?.text}/${item?.document?.uid}`;
    if (item?.document?.data?.custom_url_without_blog?.text)
      return `/${item?.document?.data?.custom_url_without_blog?.text}/${item?.document?.uid}`;
    else return `/blog/${item?.document?.uid}`;
  }
  if (item?.document?.type === POST_TYPE?.ARTICLE) {
    if (item?.document?.data?.section_category?.document?.data?.custom_url?.text)
      return `/guides/${item?.document?.data?.silos_category?.document?.uid}/${item?.document?.data?.section_category?.document?.data.custom_url?.text}/${item?.document?.uid}`;
    else
      return `/guides/${item?.document?.data?.silos_category?.document?.uid}/${item?.document?.data?.section_category?.uid}/${item?.document?.uid}`;
  }
  if (item?.document?.type === POST_TYPE.SILOS_SECTION) {
    if (item?.document?.data?.custom_url?.text) {
      return `/guides/${item?.document?.data?.silos_category?.uid}/${item?.document?.data?.custom_url?.text}`;
    } else return `/guides/${item?.document?.data?.silos_category?.uid}/${item?.document?.uid}`;
  } else {
    return `/guides/${item?.document?.silos_category?.uid}/${item?.document?.uid}`;
  }
};

export const blogCustomUrl = (item) => {
  if (item?.data?.custom_url?.text) return `/blog/${item?.data?.custom_url?.text}/${item?.uid}`;
  if (item?.data?.custom_url_without_blog?.text) return `/${item?.data?.custom_url_without_blog?.text}/${item?.uid}`;
  else return `/blog/${item?.uid}`;
};
export const guidesCustomUrl = (item) => {
  if (!item?.data?.section_category?.uid) {
    if (item?.data?.custom_url?.text) {
      return `/guides/${item?.data?.silos_category?.uid}/${item?.data?.custom_url?.text}`;
    } else {
      return `/guides/${item?.data?.silos_category?.uid}/${item?.uid}`;
    }
  } else if (item?.data?.section_category?.document?.data?.custom_url?.text)
    return `/guides/${item?.data?.silos_category?.uid}/${item?.data?.section_category?.document?.data.custom_url?.text}/${item?.uid}`;
  else return `/guides/${item?.data?.silos_category?.uid}/${item?.data?.section_category?.uid}/${item?.uid}`;
};

export const navigateBlogCustomUrl = (categoryBlogs, index) => {
  if (categoryBlogs[index]?.data?.custom_url?.text)
    return `/blog/${categoryBlogs[index]?.data.custom_url?.text}/${categoryBlogs[index]?.uid}`;
  if (categoryBlogs[index]?.data?.custom_url_without_blog?.text)
    return `/${categoryBlogs[index]?.data.custom_url_without_blog?.text}/${categoryBlogs[index]?.uid}`;
  else return `/blog/${categoryBlogs[index]?.uid}`;
};

export const specialCharacterValidation = (inputText, regexPattern) => {
  return !inputText?.match(regexPattern);
};

export const invoiceInputBackgroundColor = (inputValue) => {
  return inputValue ? MUI_COLORS.WHITE : MUI_COLORS.PAPAYA_WHIP;
};
export const replaceSpaceAndSpecialCharacter = (text) => {
  return text
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_SPACE, '-')
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_NBSP, '')
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_COMMA, '')
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_NAMES, '')
    .toLocaleLowerCase();
};

export const defaultValidationMessage = (fieldName) => {
  return `Please provide a valid ${fieldName}`;
};

export const itemsTotalPrice = (itemDetails) => {
  let totalItemsAmount = 0;
  itemDetails?.forEach((itemDetails) => {
    totalItemsAmount = totalItemsAmount + itemDetails?.itemPrice * itemDetails?.itemQuantity;
  });
  return totalItemsAmount;
};

export const tasksTotalPrice = (taskDetails) => {
  let totalTasksAmount = 0;
  taskDetails?.forEach((taskDetails) => {
    totalTasksAmount = totalTasksAmount + taskDetails?.taskHours * taskDetails?.taskQuantity;
  });
  return totalTasksAmount;
};

export const priceCalculatorThroughPercentage = (total, percentage) => {
  return (total * percentage) / 100;
};

export function percentage(total, number) {
  return (number / total) * 100;
}

export const isEven = (num) => {
  return num % 2 === 0;
};
export const redirectToDashboardPage = async () => {
  navigate('/dashboard');
};

export const concatenateSelectedCurrency = (currency, amount) => {
  return `${currency?.symbol ? currency?.symbol : currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : 0
  }`;
};
export const handleReDirectToStore = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const multiplyNumbers = (Numbersarray) => {
  const sum = Numbersarray.reduce((prevValue, curValue) => {
    return prevValue * curValue;
  }, 1);
  return sum;
};
export const concatenateSelectedCurrencyForPDF = (currency, amount, nofractionDigits) => {
  return `${currency?.name} ${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};

export const screenWidth = typeof screen !== `undefined` ? screen.width : null;

export const blogImageAccordingToLayout = (screenWidth, post) => {
  return screenWidth < 500 && post?.header_image?.thumbnails?.mobile?.url
    ? post?.header_image?.thumbnails?.mobile?.url
    : screenWidth > 500 && screenWidth < 900 && post?.header_image?.thumbnails?.tab?.url
    ? post?.header_image?.thumbnails?.tab?.url
    : post?.header_image.url;
};

export const isScriptRequiredOnBlog = (uid) => {
  if (
    uid === BLOG_UID.PAYPAL_BUSINESS_VS_PERSONAL ||
    uid === BLOG_UID.PAYPAL_PAYMENT_GUIDE ||
    uid === BLOG_UID.WITHDRAW_MONEY_PAYPAL ||
    uid === BLOG_UID.ADD_MONEY_PAYPAL
  ) {
    return true;
  } else false;
};

export const getReferralCode = (comapnyName) => {
  const firstTwoLettersOfCompanyName = comapnyName.slice(0, 3);
  const timeNow = new Date().getTime().toString();
  const last3digits = timeNow.substr(10, 12);
  return firstTwoLettersOfCompanyName + last3digits;
};

export const expriyDateFormatter = (value) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(REGEX.REPLACE_SLASH, '').substring(0, 2) +
    (expdate.length > 2 ? '/' : '') +
    expdate.replace(REGEX.REPLACE_SLASH, '').substring(2, 4);
  return expDateFormatter;
};
export const handleKeyEnter = (event, performFunction) => {
  if (event?.keyCode === CHAR_CODE?.ENTER_KEY_CODE) {
    return performFunction();
  }
};
const allowedUid = [
  'la-nao-review',
  'athenasia-review',
  'bridges-review',
  'one-ibc-review',
  'accolade-review',
  'asiabc-review',
  'get-started-review',
  'hawksford-review',
  'startupr-review'
];
export const isBrowser = typeof window !== 'undefined';
export const noFollowChecker = (review) => {
  if (allowedUid.find((validUid) => validUid === review?.uid)) {
    return false;
  } else {
    return true;
  }
};

export const addSpaceAfterFourDigits = (input) => {
  const digitsOnly = input.replace(/\D/g, '');
  return digitsOnly.replace(/(\d{4})/g, '$1 ');
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getLocalStorageValue = (key) => {
  const value = isBrowser && localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const storeDataInLocalStorage = (key, data) => {
  try {
    const dataString = JSON.stringify(data);
    localStorage.setItem(key, dataString);
    return true;
  } catch (error) {
    return false;
  }
};

export const isCompanyNameRegistered = (listOfComapny, companyName) => {
  const lowerCaseName = listOfComapny?.map((element) => element.toLowerCase());
  if (lowerCaseName?.includes(companyName.toLowerCase())) return true;
};

export const replaceWatchWithEmbed = (url) => {
  return url.replace(REGEX?.WATCH, 'embed/');
};

export const heightOfElement = (id) => {
  const element = document.getElementById(id);
  return element?.getBoundingClientRect().height;
};

export const pxToVh = (px) => {
  const viewportHeight = isBrowser && window.innerHeight;
  return (px / viewportHeight) * 100;
};

export function getExampleImageData({ image, ...props }) {
  return getImageData({
    baseUrl: image?.url,
    sourceWidth: image?.width,
    sourceHeight: image?.height,
    urlBuilder,
    pluginName: 'gatsby-plugin-sharp',
    formats: ['auto', 'webp'],
    ...props
  });
}

export function urlBuilder({ baseUrl }) {
  return baseUrl;
}

export const sendDetailsToBackOffice = async (payload) => {
  try {
    return await InvoiceApi.post(ENDPOINTS?.BUSINESS_ACCOUNT, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export const updateBusinessAccountDetailsToBackOffice = async (payload) => {
  try {
    return await InvoiceApi.put(ENDPOINTS?.BUSINESS_ACCOUNT_UPDATE, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export const checkBusinessAccount = async (payload) => {
  try {
    return await InvoiceApi.post(ENDPOINTS?.CHECK_USER_ACCOUNT, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export function getCountryNameByCode(countryCode) {
  switch (countryCode.toUpperCase()) {
    case 'SG':
      return 'Singapore';
    case 'HK':
      return 'Hong Kong';
    case 'AR':
      return 'British Virgin';
    default:
      return 'Other Jurisdiction';
  }
}
export function isValidMonthAndYear(expirationDate) {
  const [month, year] = expirationDate.split('/').map(Number);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100; // Extract last two digits
  if (year < currentYear) {
    return false;
  }
  if (month < 1 || month > 12) {
    return false;
  }
  if (year === currentYear && month <= currentDate.getMonth() + 1) {
    return false;
  }
  return true;
}

function setCookie(name, value, days) {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/';
}

export function storeArrayInCookie(cookieName, array, days) {
  const jsonArray = JSON.stringify(array);
  setCookie(cookieName, jsonArray, days);
}

function getCookie(name) {
  const nameWithEqualSign = name + '=';
  const words = document.cookie.split(';');
  for (let each = 0; each < words.length; each++) {
    let character = words[each];
    while (character.charAt(0) === ' ') character = character.substring(1, character.length);
    if (character.indexOf(nameWithEqualSign) === 0)
      return decodeURIComponent(character.substring(nameWithEqualSign.length, character.length));
  }
  return null;
}

export function getArrayFromCookie(cookieName) {
  const jsonArray = getCookie(cookieName);
  if (jsonArray) {
    return JSON.parse(jsonArray);
  }
  return null;
}

export const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];
  headingElements?.forEach((heading) => {
    const { id, innerText: title } = heading;
    if (heading?.nodeName === 'H2') {
      nestedHeadings?.push({ id, title, items: [], level: Number(heading?.nodeName?.charAt(1)) });
    } else if (heading?.nodeName === 'H3' && nestedHeadings?.length > 0) {
      nestedHeadings[nestedHeadings?.length - 1]?.items?.push({
        parentId: nestedHeadings[nestedHeadings?.length - 1]?.id,
        id,
        title,
        level: Number(heading?.nodeName?.charAt(1))
      });
    }
  });
  return nestedHeadings;
};
export const getH2AndH3HeadingsFromContainerClass = (containerClass) => {
  const divArticle = document?.querySelector(containerClass);
  const allHTags = divArticle?.querySelectorAll('h2, h3');
  if (allHTags) {
    for (let i = 0; i < allHTags?.length; i++) {
      allHTags[i]?.setAttribute('id', replaceSpaceAndSpecialCharacter(allHTags[i]?.innerText?.trim()));
    }
  }
  const newheadingElements = Array?.from(divArticle?.querySelectorAll('h2, h3'));
  const newNestedHeadings = getNestedHeadings(newheadingElements);
  return newNestedHeadings;
};

export const addNoFollowToAllLinksWhichContainNoFollowTag = () => {
  document?.querySelectorAll('a > span.nofollow')?.forEach((span) => {
    const parentLink = span?.closest('a'); // Find the closest <a> tag
    if (parentLink) {
      let existingRel = parentLink?.getAttribute('rel') || '';
      if (!existingRel?.includes('nofollow')) {
        parentLink?.setAttribute('rel', existingRel ? `${existingRel} nofollow` : 'nofollow');
      }
    }
  });
};

export function scrollToSource() {
  const sourcesElements = Array.from(document.getElementsByClassName('source'));
  for (let i = 0; i < sourcesElements.length; i++) {
    const anchorTagForSources = document.createElement('a');
    anchorTagForSources.textContent = i + 1;
    anchorTagForSources.className = 'text-xs text-bold align-top';
    anchorTagForSources.href = '#sources';
    anchorTagForSources.style.fontWeight = 'bold';
    anchorTagForSources.style.cursor = 'pointer';
    sourcesElements[i].parentNode.replaceChild(anchorTagForSources, sourcesElements[i]);
  }
}
